<style lang="scss">
.cardTextBody {
	min-height: 230px;
}
.imgHeight {
	height: 165px;
	object-fit: contain;
}
.Tcolor {
	color: #00a09c;
}
</style>

<template>
	<div>
		<b-col md="12">
			<h1 :class="[`${!$screen.sm ? 'main-page-title' : ''}`, { 'is-pwa': $isPwa() }]">
				{{ FormMSG(7, 'Carbon removal detail') }}
			</h1>
		</b-col>

		<div class="card px-3 py-4">
			<b-col cols="12">
				<h4>
					<b><i @click="$router.go(-1)" role="button" class="fa fa-arrow-left" aria-hidden="true"></i> {{ portfolioDetail.name }}</b>
				</h4>
			</b-col>
			<b-col class="mt-3" cols="12">
				<div :class="[`${!$screen.md ? 'justify-content-around mx-2' : 'd-flex justify-content-around mx-2'}`]">
					<b-col
						v-for="projet in portfolioDetail.projects"
						:key="portfolioDetail.projects.id"
						:cols="[`${!$screen.md ? '12' : '3'}`]"
						:class="[`${!$screen.md ? 'mb-3' : ''}`]"
					>
						<card-body :projet="projet" />
					</b-col>
				</div>
			</b-col>
		</div>
	</div>
</template>

<script>
import languageMessages from '@/mixins/languageMessages';
import gql from 'graphql-tag';
import CardBody from './cardBody';

const query_postfolios = gql`
	query {
		GetPortfolios {
			id
			totalCO2
			porfolio {
				id
				createdAt
				updatedAt
				name
				description
				projects {
					id
					createdAt
					updatedAt
					share
					name
					description
					offsetMethodName
					offsetMethodImageXid
					offsetMethodDescription
					projectImageXid
					portfolioId
				}
				pricePerTon
				imageXid
				climateImpactScore
			}
			updatedAt
			createdAt
		}
	}
`;

export default {
	name: 'CarbonRemovalDetails',
	mixins: [languageMessages],
	components: {
		CardBody
	},
	data() {
		return {
			portfolioDetail: {}
		};
	},

	watch: {
		'$route.params': {
			async handler(val) {
				const { id } = val;
				this.getPortfolioDetail(id);
			},
			immediate: true
		}
	},

	methods: {
		getPortfolioDetail(id) {
			this.$apollo
				.query({
					query: query_postfolios,
					fetchPolicy: 'network-only'
				})
				.then((result) => {
					const data = Object.assign({}, result.data.GetPortfolios);
					for (const item of data.porfolio) {
						if (item.id == id) {
							this.portfolioDetail = item;
							console.log({ item });
						}
					}
				})
				.catch((error) => {
					console.log(error);
					this.erreur = error;
				});
		}
	}
};
</script>
